import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';
import { UmbracoContactFormSection } from '@shared/types/umbraco';
import { GlobalContactForm } from '@phx-husky/global-contact-form';

import { gtmService } from 'utils/gtmService';

import './ContactForm.scss';

const ContactForm: FC<UmbracoContactFormSection.IData> = ({ link }) => {
  useEffect(() => {
    function iframeMessageHandler(e: MessageEvent) {
      if (e.data.message === gtmService.iframeMessageFormSubmitted) {
        gtmService.emitGenerateLead(gtmService.formNames.contactUs);
      }
    }

    window.addEventListener('message', iframeMessageHandler);

    return () => window.removeEventListener('message', iframeMessageHandler);
  }, []);

  return link ? (
    <div className="contact-form" data-testid="contact-form">
      <GlobalContactForm title="Contact form" id="phx-global-form-gatsby" src={link} />
    </div>
  ) : null;
};

export const query = graphql`
  fragment FragmentContactForm on TContactFormSection {
    properties {
      link
      script
    }
    structure
  }
`;

export default ContactForm;
