import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import StepList from 'components/StepList';
import PageSchema from 'common/PageSchema';
import { parseBoolean } from 'utils/parseBoolean';
import Section from 'common/Section';

import { IInstructionsStepList } from './model';

import './InstructionsStepList.scss';

const InstructionsStepList: FC<IInstructionsStepList> = ({ properties, className = '' }) => {
  const withImage = properties?.steps?.some((item) => item.properties?.image?.length);
  const text = properties?.text;
  const color = properties?.color;
  const { siteSettings, isSchemaEnable } = properties;

  return (
    <Section name="instructions-steps-list" color={color} intro={text}>
      <div
        className={classNames('instructions-steps-list', {
          [`${className}`]: className,
          'instructions-steps-list--small-image': parseBoolean(properties.isSmallImage),
          'instructions-steps-list--no-image': !withImage,
          'with-slider': properties?.isSliderOnMobile,
          'table-style': properties?.isTableStyle,
        })}
      >
        {!!properties?.steps?.length && isSchemaEnable && (
          <PageSchema
            additionalData={{
              title: properties.text,
              steps: properties?.steps,
              image: '',
            }}
            siteSettings={siteSettings}
            type="HowTo"
          />
        )}
        {properties?.steps?.length ? <StepList properties={properties} /> : null}
      </div>
    </Section>
  );
};

export const query = graphql`
  fragment FragmentInstructionsList on TInstructionsList {
    structure
    properties {
      anchorName
      anchorTitle
      isSmallImage
      showInMenu
      showNumber
      isSchemaEnable
      isTableStyle
      isSliderOnMobile
      steps {
        properties {
          description
          imageAutoHeight
          descriptionOpener
          descriptionOpenerLabel
          descriptionCloser
          descriptionCloserLabel
          link {
            properties {
              ariaLabel
              link {
                name
                url
              }
            }
          }
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 560) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageAlt
            }
          }
        }
      }
      text
      color {
        label
      }
    }
  }
`;

export default InstructionsStepList;
